<script>
import { mapGetters } from 'vuex'
import IconClose from '../assets/icon-close.vue'
import { ACCEPT, CREATE_CA, UPDATE_CA } from '../store/actions'

// import { CREATE_CA } from "../store/actions";

const DEFAULT_PHOTO = require('../assets/logos/default-image-profile.png')

export default {
  name: 'CreateCAModal',
  components: {
    IconClose,
  },
  props: {
    startingStep: {
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      loading: false,
      errorMsg: null,
      email: null,
      errorsMsg: {},
      hasErrors: false,
      errorCaName: false,
      textButtonNext: this.$t('button.createCA'),
      retries: 10,
    }
  },
  computed: {
    ...mapGetters(['cid', 'photoURL']),
    ...mapGetters(['userEmail']),
    isDisabled() {
      return this.hasErrors
    },
    caName: {
      get() {
        return this.$store.state.caName
      },
      set(value) {
        return this.$store.commit('setCaName', value)
      },
    },
  },
  watch: {
    caName(value) {
      if (value) {
        this.errorMsg = null
        this.errorCaName = false
      }
    },
  },
  created() {
    this.step = this.startingStep
  },
  mounted() {
    if (this.userEmail) {
      this.email = this.userEmail
    }
  },
  methods: {
    clearError(error) {
      delete this.errorsMsg[error]
      this.hasErrors = Object.keys(this.errorsMsg).length !== 0
    },
    formHasErrors() {
      this.errorsMsg = {}
      this.hasErrors = false

      if (!this.caName) {
        this.errorsMsg.errorCaName = this.$t('createCAModal.errorCaName')
      }

      if (!this.isEmailValid(this.email)) {
        this.errorsMsg.errorEmail = this.$t('createCAModal.errorEmail')
      }

      this.hasErrors
        = Object.keys(this.errorsMsg).length > 0
        && this.errorsMsg.constructor === Object

      this.$log.debug('hasErrors ', this.hasErrors)
      return this.hasErrors
    },
    close() {
      this.$emit('close')
    },
    async nextStep() {
      // case 0:
      //   this.step += 1;
      if (this.formHasErrors()) {
        this.errorCaName = true
        this.errorMsg = this.$t('createCAModal.errorCaName')
      }

      if (this.cid) {
        this.close()
      }

      switch (this.step) {
        //   break;
        case 0: // Old value: 1
          try {
            const cid = await this.$store
              .dispatch(CREATE_CA, {
                admin_email: this.email, // There is no email now, changes 26/05/2021
              })
              .catch((err) => {
                console.log(err)
                if (
                  err
                  && err.message === 'ERR_NO_BILLING'
                  && this.retries > 0
                ) {
                  this.retries--
                  this.$store.dispatch(ACCEPT).then(() => {
                    setTimeout(() => {
                      this.nextStep()
                    }, 3 * 1000)
                  })
                }
              })
            this.$store.commit('setCA_cid', cid)
            if (cid) {
              console.log(cid, CREATE_CA)

              await this.$store
                .dispatch(UPDATE_CA, {
                  name: this.caName,
                  img_url: DEFAULT_PHOTO,
                })
                .catch((err) => {
                  console.log(err)
                })
              // this.$store.commit('setAdminEmail', this.email); //remove this line ?
              this.$store.commit('tour/setStep', 0)
              this.close()
            }
          }
          catch (error) {
            console.error(error)
          }
          break

        default:
          break
      }
    },
  },

}
</script>

<template>
  <transition name="modal-fade">
    <div v-show="true" class="modal-backdrop">
      <div
        class="modal-wallid create_ca"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header id="modalTitle" class="modal-header">
          <slot name="header">
            {{ $t('createCAModal.title') }}
            <v-spacer />
            <button
              v-if="false"
              type="button"
              class="btn-close"
              aria-label="Close modal"
              @click="close"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body">
          <slot name="body">
            <v-container class="px-9">
              <v-row>
                <v-col cols="12" class="py-2">
                  <v-img
                    class="mx-auto"
                    height="90"
                    width="175"
                    contain
                    src="../assets/setup-dca-dashboard.jpg"
                  />
                </v-col>
                <v-col cols="12" class="pt-1">
                  <p class="subtitle_header mb-0">
                    {{ $t('createCAModal.subtitle') }}
                  </p>
                </v-col>
                <v-col cols="12" md="12" class="input-field pb-2">
                  <label class="" style="display: block">
                    {{ $t('createCAModal.caName') }}
                  </label>
                  <v-text-field
                    v-model="caName"
                    class=""
                    flat
                    solo
                    persistent-hint
                    :hint="$t('createCAModal.hint')"
                    :error-messages="errorsMsg.errorCaName"
                    @input="clearError('errorCaName')"
                  />
                </v-col>

                <v-col cols="12" md="12" class="input-field pb-2">
                  <label class="">{{ $t('createCAModal.email') }}</label>
                  <v-text-field
                    v-model="email"
                    class=""
                    flat
                    solo
                    persistent-hint
                    :error-messages="errorsMsg.errorEmail"
                    :disabled="!!userEmail"
                    @input="clearError('errorEmail')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn class="next" :disabled="isDisabled" @click="nextStep()">
                {{ textButtonNext }}
              </v-btn>
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.modal-wallid.create_ca {
  min-height: 550px;
  position: relative;
  .v-stepper {
    box-shadow: none;
  }

  .modal-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .container.scroll {
    max-height: 420px;
    overflow: auto;
  }
  .static_image {
    .v-image {
      position: absolute;
    }
  }
  .input-field {
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
